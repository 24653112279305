import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import {AwpService } from './awp.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,private awpService:AwpService) { }

  canActivate() {
    if (!this.awpService.config.useCaptcha) return true;

    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      return true;
    }
    this.router.navigate(['/login']);
    return true;
  }
}
