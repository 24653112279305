import { FormComponent } from './components/wizard/form/form.component';
import { AppointmentComponent } from './components/wizard/appointment/appointment.component';
import { FeeComponent } from './components/wizard/fee/fee.component';
import { CompleteComponent } from './components/wizard/complete/complete.component';
import { WizardComponent } from './components/wizard/wizard.component';

const SHARED_COMPONENTS: any[] = [
  FormComponent,
  AppointmentComponent,
  FeeComponent,
  CompleteComponent,
  WizardComponent
]

export  {
  SHARED_COMPONENTS
}
