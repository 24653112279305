import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  selected:string = "en";
  constructor() { }

  setSelected(value:string) {
    localStorage.setItem("language", value);
  }

  getSelected() {
    var storage = localStorage.getItem("language");
    return storage == null ? this.selected : storage;
  }
}
