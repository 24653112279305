import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormComponent } from './form/form.component';
import { FeeComponent } from './fee/fee.component';
import { CompleteComponent } from './complete/complete.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { CourtCommit } from '../../models/court-commit';
import { AwpService } from '../../services/awp.service';

@Component({
  selector: 'awp-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  isLinear: boolean = true;
  personDetails: CourtCommit = new CourtCommit();
  outputs = {
    completed: (componentId: number) => this.navigateComplete(componentId),
  }

  componentLoader = [
    { TemplateName: 'Fill out application', component: FormComponent, icon: '../../assets/fillform.png', completeFlag: false },
    { TemplateName: 'Make appointment', component: AppointmentComponent, icon: '../../assets/appoinment.png', completeFlag: false },
    this.awpService.usePaymentSite == true ? { TemplateName: 'Pay fee', component: FeeComponent, icon: '../../assets/dollar.png', completeFlag: false } : 
    { TemplateName: 'Complete application', component: CompleteComponent, icon: '../../assets/completed.png', completeFlag: false }
  ];

  constructor(private awpService: AwpService) { }

  ngOnInit(): void {
    this.stepper.selectedIndex = 0;
    this.awpService.Courtdetails = JSON.parse(localStorage.getItem("courtdetails"));
    this.personDetails = this.awpService.Courtdetails;
    this.getConfigValues();
  }

  navigateComplete(componentId) {
    this.componentLoader[componentId].completeFlag = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1000);
  }

  getConfigValues(){
    this.awpService.getConfigValues().subscribe(res => {
      this.awpService.usePaymentSite = res.usePaymentSystem;
    });
  }

}
