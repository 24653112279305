import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

import { AwpService} from '../shared/services/awp.service';
import { Captcha } from '../shared/models/captcha';
import { Router } from '@angular/router';

@Component({
  selector: 'awp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  siteKey: string;
  public theme: 'light' | 'dark' = 'light';
  
  public lang = 'en';
  public type: 'image' | 'audio';
  public badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright';
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public captchaIsReady = false;
  public recaptcha: any = null;
  captcha: Captcha = new Captcha();
  

  constructor(private cdr: ChangeDetectorRef, private awpService: AwpService, private route: Router) {
    this.siteKey = awpService.config.siteKey;

    if (!awpService.config.useCaptcha) {
      this.route.navigate(['/AWP/SearchByName']);
    }
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.awpService.getCaptcha(captchaResponse)
      .subscribe(res => {
          this.captcha = res;
          if (this.captcha.success) {
            localStorage.setItem('currentUser', captchaResponse);
            this.route.navigate(['/AWP/SearchByName']);
          }
        });
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

}
