export class PostBack {
    LNPSOrderID: string;
    lexisNexisProductCode: string;
    refField: string ; // DocketNo (Displays on LexisNexis Screen)
    refField1: string; // PrebookId
    productAmount: string;
    orderDateTime: string;
    emailEntered: string;
    appointmentDateTime: string;
}
