import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ConfirmationComponent } from './shared/components/wizard/confirmation/confirmation.component';
import { LayoutComponent } from './layout/layout.component';
import { WizardComponent } from './shared/components/wizard/wizard.component';
import { AuthGuardService } from './shared/services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'AWP',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'SearchByName', pathMatch: 'full' },
      { path: 'SearchByName', component: HomeComponent },
      { path: 'AwpWizard', component: WizardComponent, data: { title: 'AWP Workflow' }},
      { path: 'confirmation', component: ConfirmationComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
