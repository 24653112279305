import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxCaptchaModule } from 'ngx-captcha';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { ConfirmationComponent } from './shared/components/wizard/confirmation/confirmation.component';
import { SHARED_COMPONENTS } from './shared/shared';
import { AwpService } from './shared/services/awp.service';
import {DynamicModule} from 'ng-dynamic-component';
import { CalendarModule, CalendarNativeDateFormatter, CalendarDateFormatter,DateFormatterParams ,DateAdapter} from 'angular-calendar';
import { TranslateDirective } from './directives/translate.directive';
import { LanguageSelectorComponent } from './shared/components/language-selector/language-selector.component';
class CustomDateFormatter extends CalendarNativeDateFormatter {

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: 'numeric',
      hour12: true
    }).format(date);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LayoutComponent,
    ConfirmationComponent,
    SHARED_COMPONENTS,
    TranslateDirective,
    LanguageSelectorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    DynamicModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    })
  ],
  providers: [
    AwpService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigProvider: AwpService) => {
        return () => appConfigProvider.loadConfig();
      },
      multi: true,
      deps: [AwpService]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
