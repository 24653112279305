import { Component, OnInit } from '@angular/core';
import {TranslatorService} from '../../services/translator.service';

@Component({
  selector: 'awp-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  selected: string = 'en'
  options: any = [
    {display: "English", name:"en"},
    {display: "Spanish", name:"es"}
  ]
  constructor(private translatorService:TranslatorService) { }

  ngOnInit(): void {
    this.selected = this.translatorService.getSelected();
  }

  setLanguage() {
    this.translatorService.setSelected(this.selected);
    window.location.reload();
  }

}
