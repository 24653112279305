import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { CalendarEvent, CalendarView, CalendarEventTimesChangedEvent } from 'angular-calendar';
import * as moment from 'moment';

import { AwpService } from '../../../services/awp.service';
import { Appointment } from '../../../models/appointment';
import { from, Subject } from 'rxjs';

@Component({
  selector: 'awp-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  appointmentLst: Appointment[] = new Array<Appointment>();
  viewDate: Date = new Date();
  events: CalendarEvent[] = new Array<CalendarEvent>();
  todayDate: string;
  refresh: Subject<any> = new Subject();
  dayrange: string = "today";
  view: CalendarView = CalendarView.Week;
  appointmentTime: Date;
  @Output() completed = new EventEmitter<number>();
  flag: boolean;
  excludeDays: number[] = [0, 6];
  constructor(private awpService: AwpService, private eleRef: ElementRef) { }

  ngOnInit(): void {
    this.appointmentTime = this.appointmentTime ? this.appointmentTime : this.awpService.appointment;
    this.getAppointments();
  }

  getAppointments() {
    this.appointmentLst = this.awpService.Courtdetails.AvailableAppointments;
    this.loadCalendar();
  }

  loadCalendar() {
    this.events = [];
    if (this.appointmentLst.length > 0) {
    
      this.appointmentLst.forEach((x,v) => {
        this.events.push(
          {
            title: '',
            start: new Date(x.AppointmentTime),
            end: new Date(x.AppointmentTime),
            resizable: {
              beforeStart: false,
              afterEnd: false
            },
            draggable: false,
            meta: {
              slotsAvailable: x.SlotsAvailable,
              showTrack: moment(x.StartTime, ["HH.mm"]).format("hh:mm a") + ' - ' + moment(x.EndTime, ["HH.mm"]).format("hh:mm a"),
              appointmentTime: x.AppointmentTime
            }
          },
        );
      });
    }
    this.refresh.next();
    this.scrollIntoView();
  }

  handleEvent(): void { }

  navigateAppointment() {
    if (this.appointmentTime) {
      localStorage.setItem("appointmentTime", JSON.stringify(this.appointmentTime));
      this.awpService.appointment = this.appointmentTime;
      this.saveToCache();
    } else {
      this.awpService.showMessage("Select a Appointment Time");
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent();
    this.refresh.next();
  }

  saveToCache() {
    this.awpService.saveToCache().subscribe(res =>
      this.completed.emit(1));
  }

  clickAppointment(event) {
    this.appointmentTime = event.meta.appointmentTime;
    this.generateXML(this.awpService.personDetails);
  }

  generateXML(model) { 
    let date = moment(new Date(this.appointmentTime)).format("dddd, MMMM Do YYYY h:mm A");
    model.appointment = date.toString();
    this.awpService.generateXML(model);
  }

  scrollIntoView() {
    let eventList = this.eleRef.nativeElement.ownerDocument.body.getElementsByClassName("eventArea");
    if (eventList[0]) {
      this.eleRef.nativeElement.ownerDocument.body.getElementsByClassName("eventArea")[0].scrollIntoView({ behavior: "smooth", block: "start" });
      this.flag = !this.flag;
    }
  }

  ngAfterContentChecked() {
    if (!this.flag) {
      this.scrollIntoView();
    }
  }
}
