import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PostBack } from 'src/app/shared/models/postback';
import { AwpService } from 'src/app/shared/services/awp.service';

@Component({
  selector: 'awp-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {
  postBack: PostBack = new PostBack();
  appt: string;
  
  constructor(public awpService: AwpService, private route: Router) {}

  ngOnInit(): void {
    let preBookId = this.awpService?.Courtdetails?.PrebookId;
    if (preBookId > 0)
    {
      this.postBack.LNPSOrderID = '0';
      this.postBack.appointmentDateTime = this.awpService?.appointment;
      let email = JSON.parse(this.awpService?.formData)?.person_email;
      if (email !== undefined && email !== null)
        { this.postBack.emailEntered = email; }
        else
        { this.postBack.emailEntered = ''; };
      this.postBack.lexisNexisProductCode = this.awpService?.lexisNexisProductCode;
      this.postBack.orderDateTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.postBack.productAmount = this.awpService?.Courtdetails?.InterviewFee.toString();
      this.postBack.refField = this.awpService?.Courtdetails?.CourtDocket;
      this.postBack.refField1 = preBookId.toString();
    }
  }

  cancel() {
    this.route.navigate(['/AWP/SearchByName']);
  }

  submit() {
    this.awpService.internalPostback(this.postBack).subscribe(() => {});
    this.awpService.sendConfirmation(this.postBack).subscribe(() => {});
    this.route.navigate(['/AWP/confirmation'], {replaceUrl: true});
  }
}
