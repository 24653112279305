export class PersonDetails {
  person_last_name: string;
  person_first_name: string;
  person_middle_name: string;
  person_suffix: string;
  homeaddress_Number: number;
  homeaddress_Street: string;
  homeaddress_city: string;
  homeaddress_zip: number;
  mailingaddress_Number: number;
  mailingaddress_Street: string;
  mailingaddress_city: string;
  mailingaddress_zip: number;
  person_phone: number;
  person_cell_phone: number;
  person_dob: Date;
  person_dl_number: number;
  person_sex: string;
  person_race: string;
  person_hair_color: string;
  person_eye_color: string;
  person_height: string;
  person_weight: string;
  person_spouse: string;
  person_Num_Adults: number;
  person_Num_Childrens: number;
  person_tattoos_scars: string;
  gangAssoc: string;
  whatGang: string;
  physicallimitation: string;
  pregnant: string;
  infectiousdiseases: string;
  medication: string;
  currentmedical: string;
  recurringmedical: string;
  employer_name: string;
  employer_occupation: string;
  employer_address_Number: number;
  employer_address_Street: string;
  employer_address_city: string;
  employer_address_zip: number;
  employer_phone: number;
  employer_Sup_name: string;
  working_from: Date;
  working_to: Date;
  contact_name_lfm: string;
  contact_relationship: string;
  contact_addr_number: number;
  contact_addr_street: string;
  contact_city: string;
  contact_zip: number;
  contact_phone: number;
  contact_bus_phone: number;
  contact_name_lfm_2: string;
  contact_relationship2: string;
  contact_phone_2: number;
  applicantSig: string;
  applicantstampdate: Date;
  interviewSig: string;
  interviewstampdate: Date;
  inmateName: string;
  stampdate: Date;
  lexisNexisProductCode: string;
  appointment: string;
}
