import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Captcha } from '../models/captcha';
import { CourtCommit } from '../models/court-commit';
import * as jsonxml from 'jsontoxml';
import { FormPostConfigValues } from '../models/form-post-config-values';
import { ConfigValues } from '../models/config-values';
import { PostBack } from '../models/postback';
import {AppConfig } from '../models/app-config';
import { PersonDetails } from '../models/person-details';


@Injectable({
  providedIn: 'root'
})
export class AwpService {
  Courtdetails: CourtCommit = new CourtCommit();
  private snackbarConfig: MatSnackBarConfig = {
    panelClass: ['custom-snackbar'],
    duration: 2000,
    verticalPosition: 'top',
    horizontalPosition: 'center'
  };

  formData: any;
  appointment: any;
  xml: string;
  lexisNexisFormPostEP: any;
  lexisNexisCode: any;
  bypassReceiptUrl: string;
  lexisNexisProductCode: string;
  config: AppConfig;
  personDetails: PersonDetails = new PersonDetails();
  usePaymentSite: boolean;
  
  constructor(private http: HttpClient, public snackBar: MatSnackBar) { }

  loadConfig() {
    let dataUrl = 'search';
    return this.http.get<AppConfig>(dataUrl).toPromise()
      .then(res => this.config = res);
  }
  
  handleError(error: any) {
    let errMsg = (error.message) ? error.message
      : error.status
        ? '${error.status} - ${error.statusText}'
        : 'Server error';
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  showMessage(message: string) {
    this.snackBar.open(message, null, this.snackbarConfig);
  }

  dismissMessage() {
    this.snackBar.dismiss();
  }

  getCaptcha(response: string): Observable<Captcha> {
    let dataUrl = 'captcha/GetCaptchaResponse';
    let params = new HttpParams({
      fromObject: {
        captchaResponse: response
      }
    });
    return this.http.get<Captcha>(dataUrl, { params })
      .pipe(catchError(this.handleError));
  }

  invokeWebService(response: string) {
    let dataUrl = 'Search/InvokeWebService';
    let params = new HttpParams({
      fromObject: {
        searchValue: response
      }
    });
    return this.http.get(dataUrl, { params })
      .pipe(catchError(this.handleError));
  }

  internalPostback(postBack: PostBack) : Observable<any> {
    let dataUrl = 'api/LexisNexis/InternalPostBack';

    let body = JSON.stringify(postBack);

    let headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })};

    return this.http.post<any>(dataUrl, body, headers)
      .pipe(catchError(this.handleError));
  }

  generateXML(model) {
    var formPost = this.formatForXml(model);
    var xml = '<?xml version="1.0" encoding="utf-8"?>' + jsonxml(formPost);
    this.xml = xml;
  }

  formatForXml(model: any) {
    var obj = {
      multiLineFormPostRequest: {
        lexisNexisCode: this.lexisNexisCode,
        bypassReceiptUrl: this.bypassReceiptUrl,
        customerInformation: {
          billingInformation: {
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: null,
            email: '',
            phone: null,
          },
          shippingInformation: {
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: null,
            email: '',
            phone: null,
          }
        },
        paymentLineItems: {
          productLines: {
            productAmount: this.Courtdetails.InterviewFee,
            lexisNexisProductCode: this.lexisNexisProductCode ? this.lexisNexisProductCode : 'AWP',
            refField: this.Courtdetails.CourtDocket,
            refField1: this.Courtdetails.PrebookId,
            refField2: model.appointment,
            refField3: '',
            refField4: '',
            refField5: ''
          }
        }
      }
    }
    console.log("model", model)

    obj.multiLineFormPostRequest.customerInformation.billingInformation.firstName = model.person_first_name ? model.person_first_name : '';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.lastName = model.person_last_name ? model.person_last_name : '';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.city = model.mailingaddress_city ? model.mailingaddress_city : model.homeaddress_city ? model.homeaddress_city : '';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.zip = model.mailingaddress_zip ? model.mailingaddress_zip : model.homeaddress_zip ? model.homeaddress_zip : '';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.addressLine1 = (model.mailingaddress_Number && model.mailingaddress_Street) ? (model.mailingaddress_Number + ' ' + model.mailingaddress_Street) : (model.homeaddress_Number && model.homeaddress_Street) ? (model.homeaddress_Number + ' ' + model.homeaddress_Street) : '';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.phone = model.person_cell_phone ? model.person_cell_phone : model.person_phone ? model.person_phone : null;
    obj.multiLineFormPostRequest.customerInformation.billingInformation.state = 'CA';
    obj.multiLineFormPostRequest.customerInformation.billingInformation.email = model.person_email;
    return obj;
  }

  saveToCache() {
    let dataUrl = 'api/LexisNexis/SaveToCache';
    let courtdetail = JSON.parse(JSON.stringify(this.Courtdetails));
    courtdetail.AvailableAppointments = [];
    let headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let params = {
      'courtdetails': courtdetail,
      'formData': this.formData,
      'appointmentDate': this.appointment
    };
    let body = JSON.stringify(params);
    return this.http.post(dataUrl, body, headers)
      .pipe(catchError(this.handleError));
  }

  clearCache() {
    let dataUrl = 'api/LexisNexis/SaveToCache';
    let courtdetail = new CourtCommit();
    courtdetail.AvailableAppointments = [];
    let headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let params = {
      'courtdetails': courtdetail,
      'formData': '',
      'appointmentDate': this.appointment //let appt persist
    };
    let body = JSON.stringify(params);
    return this.http.post(dataUrl, body, headers)
      .pipe(catchError(this.handleError));
  }

  getFormPostValues(){
    let dataUrl = 'api/LexisNexis/GetFormPostValues';
    return this.http.get<FormPostConfigValues>(dataUrl)
      .pipe(catchError(this.handleError));
  }

  getConfigValues(){
    let dataUrl = 'api/LexisNexis/GetConfigValues';
    return this.http.get<ConfigValues>(dataUrl)
      .pipe(catchError(this.handleError));
  }

  sendConfirmation(postBack: PostBack) : Observable<any> {
    let dataUrl = 'api/LexisNexis/SendConfirmation';

    let body = JSON.stringify(postBack);

    let headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })};

    return this.http.post<any>(dataUrl, body, headers)
      .pipe(catchError(this.handleError));
  }
}
