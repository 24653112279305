import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PersonDetails } from '../../../models/person-details';
import { AwpService } from '../../../services/awp.service';

@Component({
  selector: 'awp-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  model: PersonDetails = new PersonDetails();
  @Output() completed = new EventEmitter<number>();

  constructor(private awpService: AwpService, private route: Router) { }

  ngOnInit(): void {
    this.getFormPostValues();
    if (!this.isEmpty(this.awpService.personDetails)) {
      this.model = this.awpService.personDetails;
    } else if(this.awpService.Courtdetails) {
      this.model.person_last_name = this.awpService.Courtdetails.LastName;
      this.model.person_first_name = this.awpService.Courtdetails.FirstName;
      this.model.inmateName = this.awpService.Courtdetails.LastName + ', ' + this.awpService.Courtdetails.FirstName;
      this.model.lexisNexisProductCode = 'AWP';
    }
  }

  isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  saveApplicationForm() {
    this.awpService.formData = JSON.stringify(this.model);
    this.awpService.lexisNexisProductCode = this.model.lexisNexisProductCode;
    this.awpService.personDetails = this.model;
    this.completed.emit(0); 
  }

   getFormPostValues(){
    this.awpService.getFormPostValues().subscribe(res => {
      this.awpService.lexisNexisFormPostEP = res.lexisNexisFormPostEP;
      this.awpService.lexisNexisCode = res.lexisNexisCode;
      this.awpService.bypassReceiptUrl = res.partialBypassReceiptUrl + this.awpService.Courtdetails.PrebookId;
    })
  } 

  cancelForm() {
    this.awpService.formData = '';
    this.route.navigate(['/AWP/SearchByName']);
  }
}
