import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PostBack } from 'src/app/shared/models/postback';
import { AwpService } from 'src/app/shared/services/awp.service';

@Component({
  selector: 'awp-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  postBack: PostBack = new PostBack();
  appt: string;

  languageList = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish ' }
  ];
  
  constructor(public awpService: AwpService, private route: Router) {}

  ngOnInit(): void {
    this.appt = new Date(this.awpService.appointment).toLocaleString();
    this.awpService.clearCache().subscribe(() => {});
  }

  printPage() {
    window.print();
  }

  cancel() {
    this.route.navigate(['/AWP/SearchByName']);
  }

}
