import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AwpService } from 'src/app/shared/services/awp.service';

@Component({
  selector: 'awp-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {
  feeAmount: number;
  
  constructor(public awpService: AwpService, private route: Router) {}

  ngOnInit(): void {
    this.feeAmount = +(this.awpService?.Courtdetails?.InterviewFee);
    console.log(this.awpService.xml)
  }

  cancelfee() {
    this.route.navigate(['/AWP/SearchByName']);
  }
}
