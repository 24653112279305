import { Appointment } from "./appointment";

export class CourtCommit {
  AppSubmittedFlag: number;
  CourtCommitDate: Date;
  CourtDocket: string;
  FeePaidFlag: number;
  FirstName: string;
  InterviewDate: Date;
  InterviewFee: number;
  LastName: string;
  PrebookId: number;
  SchInterviewFlag: number;
  AvailableAppointments:Appointment[] = new Array<Appointment>();
}
