import { Component, OnInit, LOCALE_ID, Inject  } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { AwpService } from '../shared/services/awp.service';

@Component({
  selector: 'awp-home',
  templateUrl: './home.component.html',
})

export class HomeComponent implements OnInit {
  lastName: string;
  courtDocket: string;
  showWizard: boolean;
  iswizardCompleted: boolean;
  errorMessage: string;
  showError: boolean;
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish ' }
  ];
  constructor( private awpService: AwpService, private route: Router, 
      @Inject(LOCALE_ID) protected localeId: string) {
       }

  ngOnInit(): void {
    //localStorage.clear();
  }

  search() {
    let param = this.lastName + '|' + this.courtDocket + '|' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.awpService.invokeWebService(param).subscribe((res: any) => {
      if (res) {
        this.showError=false;
        localStorage.setItem("courtdetails", JSON.stringify(res.CourtCommit[0]));
        this.route.navigate(['/AWP/AwpWizard']);
      } else {
        this.showError=true;
        // this.awpService.showMessage("Cannot locate record, enter a valid court commit record");
      }
    }, error => this.errorMessage = error);
  }

  clearFields() {
    this.courtDocket = '';
    this.lastName = '';
  }

 
}
