import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input} from '@angular/core';
import { TranslatorService } from '../shared/services/translator.service';

@Directive({
  selector: '[awpTranslate]'
})
export class TranslateDirective {
  @Input('awpTranslate') source: string;
  dictionary:any;
  original:string;
  constructor(private el: ElementRef, private httpClient: HttpClient, private translatorService:TranslatorService) { 
  }

  
  ngOnInit() {
    this.loadDictionary();
  }

  loadDictionary() {
    this.httpClient.get('./assets/translation/'+this.source+'.json').subscribe(data => {
      this.dictionary = data;
      this.original = this.el.nativeElement.innerText;
      this.el.nativeElement.innerText = this.returnTranslation();
    });
  }

  private returnTranslation() {
    var result = this.dictionary.filter(f => f["en"] == this.original)[0];
    var selected = this.translatorService.getSelected(); 
    return result == null ? this.original : result[selected];
  }

  destroyListener(){
    window.removeEventListener('storage', this.handleStorageChanges, true);
  }

  private handleStorageChanges=()=>{console.log("storage changed")
      this.loadDictionary();
  }

  ngOnDestroy() {
    this.destroyListener();
  }
}
